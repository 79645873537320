<template>
    <div>
        <qy-top-nav title="收货地址" />
        <van-address-list v-model="chosenAddressId" :list="addressList" default-tag-text="默认" 
        add-button-text="新增" @add="onAdd"  @select="onSelect">
          
        </van-address-list>
        
        
    </div>
</template>
<script type="text/javascript">
import request from "@/utils/request";
export default {
    name: "Address",
    components: {},
    data() {
        return {
            chosenAddressId: null,
            addressList: null,
			proxiedUser:{},
            selectRow: null
        };

    },
    created() {
		this.proxiedUser=this.getProxiedUser();
		// console.log(this.proxiedUser)
        this.refreshList()
    },
    computed: {

    },
    methods: {
        refreshList() {
            request.post("/api/consignee/list", {
				userId:this.proxiedUser.userId
			}).then(rs => {
                if (rs.data) {
                    for (let i = 0, data = rs.data; i < data.length; i++) {
                        let consign = data[i];
                        consign.tel = consign.phone;
                        consign.isDefault = !!consign.isDefault;
                        if(consign.isDefault){
                            this.chosenAddressId=consign.id;
                            this.selectRow=consign;
                        }
                        consign.address = consign.city + consign.county + " " + consign.address;
                    }
                    if(!this.selectRow){
                        this.selectRow==rs.data[0];
                    }
                }
                this.addressList = rs.data;

            })
        },
        onAdd() {
            this.$router.push({ path: '/address/add', query: {} })
        },
        onSelect(row) {
            this.selectRow=row;
            this.storage.set("selectAddress",JSON.stringify(this.selectRow));
            this.$router.back();
        },
        confirm() {
            
        }
    }
}
</script>
<style type="text/css" scoped="">
.address {
    display: flex;
    align-items: center;
}
.van-icon-edit{
    display: none;
}
.xing span {
    background: red;
    display: inline-block;
    border-radius: 17px;
    padding: 5px 7px;

}

.address>div {
    min-width: 3em;
    text-align: center;
}

.address .info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.info p {
    margin: 8px 4px;
}

.address>div:nth-of-type(3) {}

.delBtn {
    float: right;
}
</style>